<template>
  <Swiper
    :modules="[Navigation, Pagination, A11y]"
    :slides-per-view="1"
    :space-between="50"
    navigation
    :pagination="{ clickable: true }"
    class="h-[600px]"
  >
    <SwiperSlide class="relative swiper-slide h-[600px] w-full" v-for="slide in slides" :key="slide.id">
      <div class="h-full w-full aspect-video">
        <NuxtImg :src="getCmsImage(slide.image)" format="webp" loading="lazy" alt="" class="h-[600px] object-cover w-full" />
      </div>
      <div class="absolute inset-0 h-full w-full flex items-center bg-black/40">
        <VContainer class="text-white">
          <h2 class="text-5xl font-bold line-clamp-2 mb-6">
            {{ slide.heading }}
          </h2>
          <div class="text-white w-full max-w-lg mt-4">
            {{ slide.subheading }}
          </div>
          <template v-if="slide.callToAction && slide.callToAction.url">
            <div class="mt-4">
              <nuxt-link class="btn btn-primary" :to="localePath(replaceBasePath(slide.callToAction.url))">
                {{ slide.callToAction.text }}
              </nuxt-link>
            </div>
          </template>
        </VContainer>
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<script lang="ts" setup>
import { getCmsImage, replaceBasePath } from '@/composables/useCms';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, A11y } from 'swiper/modules';

interface Slide {
  id: string;
  heading: string;
  subheading: string;
  image: {
    title: string;
    url: string;
  }[];
  callToAction: {
    text: string;
    customText: string;
    url: string;
  };
}

withDefaults(
  defineProps<{
    heading?: string | null;
    slides: Slide[];
  }>(),
  {
    slides: () => [],
  },
);

const localePath = useLocalePath();
</script>
